import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import { useForm, SubmitHandler } from 'react-hook-form';
import useLocalStorage from "use-local-storage";
import { FormCheckboxElementConfig, FormInputElementConfig, FormSelectElementConfig, FormTextareaElementConfig, SuperHeroFromProps } from '../typescript/component';
import { HeroFormFields, HeroQuality } from '../typescript/super-hero-form';
import StackClient from "../sdk/management";
import { renderInput } from './form/input-text';
import { renderSelect } from './form/select-field';
import { renderCheckbox } from './form/checkbox-field';
import { renderTextarea } from './form/textarea';
import Loader from './atoms/loader';
import ErrorMessage from '../pages/errorMessage';
import { renderConsent } from './consent';
import VerticallyCenteredModal from './modal';
import { Accordion } from 'react-bootstrap';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().trim().required("Please enter you name"),
  job_title: yup.string().trim().required("Please enter your full job title"),
  job_description: yup.string().trim().required("Please enter your full job description"),
  organization: yup.string().trim().required("Please enter the name of your organization"),
  work_email: yup.mixed<string>()
    .test("email", "Please enter your email", value => validateEmail(value)
    ),
  // phone_number: yup.string().required("Please enter your phone number"),
  original_image: yup
    .mixed<FileList>()
    .test(
      "fileEmpty",
      "Please choose an image to upload!",
      files =>
        !files || // Check if `files` is defined
        files.length > 0
    )
    .test("fileSize", "Maximum upload size 50MB", (value) => {
      const file = value as FileList
      return file && file[0]?.size < 52428800;
    }),
  heroConsent: yup.mixed()
    .test("accepted", "Please accept the terms and conditions", (value) => {
      return value && value === "I accept the terms and conditions"
    }),
}).required();

export default function SuperHeroForm({ form }: { form: SuperHeroFromProps }) {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [errorStatus, setErrorStatus] = useState({ error: false, message: "" })
  const [heroEntry, setHeroEntry] = useLocalStorage<HeroFormFields>("heroEntry", {} as HeroFormFields);
  const { register, handleSubmit, setError, formState: { errors }, control, reset } = useForm<HeroFormFields>({
    resolver: yupResolver(schema),
    defaultValues: {...heroEntry}
  });
  let stepProgress: any = window.localStorage.getItem("stepProgress");
  if (stepProgress === null) {
    stepProgress = 0;
  }
  const [, setProgress] = useLocalStorage("stepProgress", stepProgress);
  const [modalShow, setModalShow] = useState(false);
  const [showClear, ] = useState(heroEntry?.uid ? true : false);

  console.debug("formStateErrors:", errors);

  console.log("Hero Entry", heroEntry);


  const onSubmit: SubmitHandler<HeroFormFields> = data => {
    delete data["aprimo_hero_card_url"];
    delete data["aprimo_record_id"];
    delete data["aprimo_composable_card_url"];
    delete data["aprimo_high_resolution"];
    delete data["generating"];
    data["accepted"] = false;
    setLoading(true)
    StackClient.createHero(data)
      .then(result => {
        // console.log("result", JSON.stringify(result));
        setHeroEntry(result.entry)
        setProgress(0);
        navigate("/my-hero")
      })
      .catch(err => {
        console.log("error handling: ", err)
        setErrorStatus({ error: true, message: err?.response?.data?.error_message })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  if (loading) return <Loader />
  console.log("Error Status", errorStatus);
  // if (errorStatus?.error) setModalShow(true)
  if (errorStatus?.error) return <ErrorMessage message={errorStatus.message} title="Whoops! Looks like you forgot something. Please make sure you fill out all fields and try again." />

  const combineFormElements = (elements: Array<any>) =>
    <div className='with-border'>
      {[...elements]}
    </div>

  const clearForm = () => {
    setHeroEntry({} as HeroFormFields);
    reset({ 
      name: "",
      job_title: "",
      job_description: "",
      organization: "",
      work_email: "",
      // superhero_qualities: {} as HeroQuality,
      gender_qualities: [],
    })
  }

  return (
    <>
      <div className='accordian-container max-width'>
        {
          form?.creative_ideas?.[0]?.title && (
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header><h3 className='accordian-header'>{form?.creative_ideas?.[0]?.title}</h3></Accordion.Header>
                <Accordion.Body>
                  {/* {
                      form?.creative_ideas?.[0]?.list_of_ideas?.idea?.map((idea: string, index: number) => (
                        <li key={`${index}_${idea}`}><div style={{display: "inline"}} dangerouslySetInnerHTML={{__html: `${idea}`}} ></div></li> 
                      ))
                    } */}
                  <div dangerouslySetInnerHTML={{ __html: `${form?.creative}` }}></div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        }
      </div>
      <div className='contact-page-section max-width'>
        {form.description ? (
          <div className='contact-page-content'>
            {form.title && <h1>{form.title}</h1>}
            <div >{form?.file?.url &&
              <img
                style={{
                  maxWidth: "100%;",
                  textAlign: "center"
                }}
                src={form?.file?.url}
              />}</div>
            <div className='hero-form-title mb-3'>{form?.description && form?.description}</div>
          </div>
        ) : (
          <Skeleton height={400} />
        )}

        <div className='contact-page-form' >
          {
            showClear && (
              <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                <button className="btn-clear-container rounded" onClick={clearForm}>Clear</button>
              </div>
            )
          }
          {form.form_config ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {
                  Object.keys(form.form_config).map((name: string) => {
                    const element = form.form_config[name]
                    if (element.type === "text" || element.type === "file" || element.type === "number")
                      return renderInput(name, errors, register, element as FormInputElementConfig)

                    if (element.type === "select")
                      return renderSelect(name, errors, register, element as FormSelectElementConfig)

                    // quick workaround to order elements TODO: use order style
                    if (name === "heroConsent" && form.consent && element.type === "checkbox") {
                      return combineFormElements([renderConsent(form.consent), renderCheckbox(name, errors, register, element as FormCheckboxElementConfig)])
                    }

                    if (element.type === "checkbox") {
                      return renderCheckbox(name, errors, register, element as FormCheckboxElementConfig)
                    }

                    if (element.type === "textarea")
                      return renderTextarea(name, errors, register, element as FormTextareaElementConfig)
                  })
                }
                <input className="btn primary-btn rounded mt-2" type="submit" value="Submit" />
                {/* <VerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                title="Whoops! Looks like you forgot something. Please make sure you fill out all fields and try again."
                message={errorStatus?.message}
              /> */}
              </>
            </form>
          ) : (
            <Skeleton height={400} />
          )}
        </div >
      </div >
    </>
  );
}


function validateEmail(mail?: string) {
  if (mail && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return (true)
  }
  return (false)
}

// function validatePhoneNumber(number: string) {
//   if (/^\+\d{1,3}\s?\d{6,14}$/.test(number)) {
//     return (true)
//   } 
//   return (false)
// }
