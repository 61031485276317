import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { getHeroFormListRes } from "../helper";
import { HeroEntry } from "../typescript/super-hero-form";
import HeroView, { HERO_IMAGE } from "../components/hero-view";



export default function HeroPage() {
  const history = useNavigate();
  const [searchParams] = useSearchParams()
  const entriesLimit = searchParams.get('rand') ? 20 : 1
  const [getEntry, setEntry] = useState({} as HeroEntry);
  const [error, setError] = useState(false);

  async function fetchData() {
    getHeroFormListRes(0, entriesLimit, "updated_at")
      .then(result => {
        if (entriesLimit > 1) {
          const rand = Math.floor(Math.random() * result[0]?.length)
          setEntry(result[0]?.[rand])
          return
        }
        setEntry(result[0][0])
      })
      .catch(error => {
        console.error(error);
        setError(true)
      })
  }


  useEffect(() => {
    fetchData();
    error && history("/404");
    const latestHero = setInterval(() => fetchData(), 30000)

    return () => {
      clearInterval(latestHero)
    }
  }, [error]);

  return <HeroView entry={getEntry} type={HERO_IMAGE.HIGH_RESOLUTION} latestHeroPage />
}
