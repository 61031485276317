import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Home from "./pages/index";
import Blog from "./pages/blog";
import BlogPost from "./pages/blog-post";
import Error from "./pages/error";
import "./styles/third-party.css";
import "./styles/style.css";
import "./styles/modal.css";
import "@contentstack/live-preview-utils/dist/main.css";
import "react-loading-skeleton/dist/skeleton.css";
import { EntryProps } from "../src/typescript/layout";
import HeroGallery from "./pages/heroes-gallery";
import HeroPage from "./pages/hero";
import MyHeroPage from "./pages/my-hero";
import Landing from "./pages/landing";
import HeroCardPage from "./pages/hero-card";
import HeroRandomPage from "./pages/hero-random";
import AboutUs from "./pages/about-us";
import Gallery from "./pages/gallery";

function App() {
  const [getEntry, setEntry] = useState({} as EntryProps);

  function getPageRes(response: EntryProps) {
    setEntry(response);
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout entry={getEntry} />}>
          <Route index element={<HeroGallery entry={getPageRes} />} />
          <Route path="/:page" element={<Home entry={getPageRes} />} />
          {/* <Route path="/blog" element={<Blog entry={getPageRes} />} /> */}
          <Route path="/gallery" element={<Gallery entry={getPageRes} />} />
          <Route path="/hero" element={<HeroPage/>} />
          <Route path="/hero-card" element={<HeroCardPage/>} />
          <Route path="/hero-random" element={<HeroRandomPage/>} />
          <Route path="/my-hero" element={<MyHeroPage/>} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/blog/:blogId"
            element={<BlogPost entry={getPageRes} />}
          />
          <Route path="/404" element={<Error />}></Route>
          <Route path="*" element={<Error />}></Route>
        </Route>
        <Route path="/landing" element={<Landing/>} />
      </Routes>
    </div>
  );
}

export default App;
